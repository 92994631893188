import * as React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import msImg from "../images/mission_statementb.jpeg";
function MainContent(props) {
  return (
    <>
      <Row>
        <Col xs={7}>
          <p>
            Since 1964 United Laboratories, Inc. has been a leader in developing
            environmentally safe products for the medical community. United
            established Earth Smart Product Certification in 1992 to meet the
            demand for highly effective yet safe products.
          </p>
          <p>
            As your responsible solutions partner, United continues to develop
            new products and new technologies to insure that our customers are
            on the leading edge of product solutions that will exceed the
            demands of the United’s medical industry customers.
          </p>
          <p>
            As an Employee Owned Company, everyone at United is dedicated to
            superior service, quality products, Responsible Innovations, and
            “Green Products that Work”. With over 20,000 customers nationwide,
            United is the premier vendor of Earth Smart Products.
          </p>
          <p>
            United Labs is a contracted supplier for Vizient, MHA, Navigator,
            LeadingAge, Value1st, Salvation Army and Fresenius Medical Care.
          </p>
        </Col>
        <Col>
          <Image fluid src={msImg} />
        </Col>
      </Row>
      About Us
    </>
  );
}

export default function IndexPage({ data }) {
  return <MainContent />;
}
